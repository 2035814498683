<template>
  <div class="list-view product-checkout mt-0 user-ecommerce-checkout-cart">
    <user-e-commerce-checkout-cart-product />
    <div class="wrapper-cart-info-details">
      <slot name="orderType" />
      <user-e-commerce-cart-info
        :button-text-next="'Continue'"
        :selected-delivery-option="selectedDeliveryOption"
        @next-step="nextStep"
      />
    </div>
  </div>
</template>

<script>
import UserECommerceCheckoutCartProduct from './UserECommerceCheckoutCartProduct.vue'
import UserECommerceCartInfo from './UserECommerceCartInfo.vue'

export default {
  components: {
    UserECommerceCheckoutCartProduct,
    UserECommerceCartInfo,
  },
  props: {
    selectedDeliveryOption: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    nextStep() {
      this.$emit('next-step')
    },
  },
}
</script>
