<template>
  <div class="checkout-options user-ecommerce-cart-info">
    <b-card>
      <div class="price-details">
        <h6 class="price-title">
          {{ $t('Cart Details') }}
        </h6>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title">
              {{ $t('Total Items Price') }}
            </div>
            <div class="detail-amt">
              {{ userCurrency }} {{ toFixed(entireCart.total_item_price) }}
            </div>
          </li>
          <li
            v-if="entireCart.shop_discount > 0"
            class="price-detail text-success"
          >
            <div class="detail-title">
              {{ $t('Shop Offer') }}
            </div>
            <div class="detail-amt discount-amt">
              {{ userCurrency }} {{ toFixed(entireCart.shop_discount) }}
            </div>
          </li>
          <li
            v-if="entireCart.promocode_id"
            class="price-detail text-success"
          >
            <div class="detail-title">
              {{ $t('Coupon') }} - ({{ promocodeName }})
            </div>
            <div class="detail-amt discount-amt">
              {{ userCurrency }} {{ toFixed(entireCart.promocode_amount) }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              {{ $t('Tax') }}
            </div>
            <div class="detail-amt discount-amt">
              {{ userCurrency }} {{ toFixed(entireCart.shop_gst_amount) }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              {{ $t('Packing Charge') }}
            </div>
            <div class="detail-amt discount-amt">
              {{ userCurrency }} {{ toFixed(entireCart.shop_package_charge) }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              {{ $t('Delivery Charge') }}
            </div>
            <div
              v-if="entireCart.delivery_charges || deliveryCharge"
              class="detail-amt discount-amt"
            >
              {{ userCurrency }} {{ toFixed(deliveryCharge ? deliveryCharge : entireCart.delivery_charges) }}
            </div>
            <div
              v-else
              class="detail-amt discount-amt text-right"
            >
              {{ $t('Calculated at Checkout') }}
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title detail-total">
              {{ $t('GRAND TOTAL') }}
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ userCurrency }} {{ toFixed(deliveryCharge ? ((entireCart.total_price - (entireCart.delivery_charges ? entireCart.delivery_charges : 0)) + deliveryCharge) : entireCart.total_price) }}
            </div>
          </li>
        </ul>
        <b-button
          v-if="!entireCart.no_options_available"
          variant="primary"
          block
          @click="$emit('next-step')"
        >
          {{ buttonTextNext }}
        </b-button>
        <h4
          v-else
          class="text-danger mb-0 text-center"
        >
          {{ 'Delivery is not possible on selected address' }}
        </h4>
      </div>
    </b-card>
    <b-card
      v-if="isPaymentPage"
      class="mt-2"
    >
      <label class="form-control-label d-flex align-items-center">
        <input
          v-model="isContactLess"
          type="checkbox"
          class="mr-1"
          @change="$emit('contact-less-changed', isContactLess)"
        >
        <img :src="$helpers.getIcons('contact-less')">
      </label>
      <p>
        {{ $t('Opt for contactless delivery & our delivery agent will leave order at your door/gate (not applicable on COD orders)') }}
      </p>
      <div class="w-75 mx-auto">
        <img
          :src="require('@/assets/images/app-icons/contact_less_delivery.png')"
          class="w-100"
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
  },
  props: {
    buttonTextNext: {
      type: String,
      default: 'Continue',
    },
    isPaymentPage: {
      type: Boolean,
      default: false,
    },
    selectedDeliveryOption: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isContactLess: false,
    }
  },
  computed: {
    deliveryCharge() {
      // return this.selectedDeliveryOption && this.selectedDeliveryOption.id ? this.selectedDeliveryOption.rate : 0
      return 0
    },
    entireCart() {
      return this.$store.state['app-ecommerce'].entireCart
    },
    promocodeList() {
      return this.$store.state['app-ecommerce'].promocodes
    },
    courierDetail() {
      if (this.entireCart.carts && this.entireCart.carts[0] && this.entireCart.carts[0].courier) {
        return this.entireCart.carts && this.entireCart.carts[0] && this.entireCart.carts[0].courier
      }
      return null
    },
    userCurrency() {
      const cart = this.entireCart
      if (cart && cart.carts && cart.carts[0] && cart.carts[0].store) {
        return cart.carts[0].store.currency_symbol
      }
      return ''
    },
    promocodeName() {
      const cart = this.entireCart
      if (cart && cart.promocode_id && this.promocodeList) {
        const isExist = this.promocodeList.find(x => x.id === cart.promocode_id)
        if (isExist) {
          return isExist.promo_code
        }
      }
      return ''
    },
    storeName() {
      const cart = this.entireCart
      if (cart && cart.carts && cart.carts[0] && cart.carts[0].store) {
        return cart.carts[0].store.store_name
      }
      return ''
    },
  },
  methods: {
    toFixed(value, fixed = 2) {
      return parseFloat(value).toFixed(fixed)
    },
  },
}
</script>
